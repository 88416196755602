import { CommonModule } from '@angular/common'
import { Component, Input, OnInit, inject } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { Router, RouterLink } from '@angular/router'
import { AlertService } from '@core/services/alert.service'
import { TaigaUiImports } from '@core/ui/taiga-ui-imports'
import { CanDeleteDirective } from '@modules/auth/directives/can.directive'
import { ReleaseNote } from '@modules/update/models/release-note.model'
import { ReleaseNoteApiService } from '@modules/update/services/release-note-api.service'
import { ReleaseNoteFormService } from '@modules/update/states/release-note-form.service'
import { ReleaseNoteListStateService } from '@modules/update/states/release-note-list-state.service'
import { getAdminRoutes } from '@pages/admin/admin.routes'
import { TuiDialogService } from '@taiga-ui/core'

@Component({
    selector: 'app-release-note-card',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ...TaigaUiImports,
        CanDeleteDirective,
        RouterLink,
    ],
    templateUrl: './release-note-card.component.html',
    styleUrls: ['./release-note-card.component.scss'],
})
export class ReleaseNoteCardComponent implements OnInit {
    releaseNoteFormService = inject(ReleaseNoteFormService)
    releaseNoteApiService = inject(ReleaseNoteApiService)
    private alertService = inject(AlertService)
    private dialog = inject(TuiDialogService)
    private router = inject(Router)
    private releaseNoteListStateService = inject(ReleaseNoteListStateService)

    @Input() releaseNote: ReleaseNote | null = null

    releaseDate: string
    edit(releaseNote: ReleaseNote) {
        this.router.navigateByUrl(
            `/${getAdminRoutes().editReleaseNote.path.replace(':id', releaseNote.id)}`,
        )
    }

    delete(releaseNote: ReleaseNote) {
        this.releaseNoteListStateService.deleteReleaseNote(releaseNote.id)
    }
    ngOnInit() {
        this.releaseDate = this.releaseNote.releaseDate as string
    }
}
